<template>
  <v-container fluid>
    <v-card flat outlined tile>
      <v-toolbar flat color="bg_section">
        <v-toolbar-title>Crea Nuovo Asset</v-toolbar-title>
      </v-toolbar>
      <v-divider />
      <v-card-text>
        <CustomerAssetsForm :title="null" @submitted="onSubmitted" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import CustomerAssetsForm from '@components/customer_assets/CustomerAssetsForm.vue'
export default {
  page: {
    title: 'Crea nuovo',
  },
  name: 'AssetsCreate',
  components: { CustomerAssetsForm },
  props: {
    replacementOf: {
      type: String,
    },
    ticketId: {
      type: String,
    },
    customerId: {
      type: String,
    },
    customerUserId: {
      type: String,
    },
    plansSubscriptionId: {
      type: String,
    },
  },
  mounted() {
    if (this.replacementOf) {
      console.info('creating replacement slot...')

      this.$nextTick(() => {
        this.reset()
        this.setCurrent({
          customer_id: this.customerId,
          customer_user_id: this.customerUserId,
          replacement_of: this.replacementOf,
          plans_subscription_id: this.plansSubscriptionId,
          status: 'Assegnato',
        })
      })
    }
  },
  methods: {
    ...mapMutations('customersAssets', {
      setCurrent: 'SET_CURRENT',
      reset: 'RESET_CURRENT',
    }),
    onSubmitted(id) {
      this.reset()
      if (this.ticketId) {
        this.$router.push({
          name: 'tickets_detail',
          params: { id: this.ticketId },
        })
        return
      }

      if (id) this.$router.push({ name: 'assets_detail', params: { id } })
    },
  },
}
</script>
